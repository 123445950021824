/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useMutation } from '@apollo/client'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { parseCookies, setCookie } from 'nookies'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDebounce } from 'src/hooks/useDebounce'
import {
  Currency,
  IMutation,
  IMutationSaveUserPreferencesArgs,
  Language
} from 'src/types/graphql'
import { useAuthProvider } from './AuthProvider'

type CurrencyAndLanguageType = {
  currentCurrency: Currency
  currentLocale: Language
  selectedCurrency: Currency
  selectedLocale: Language
  handleCurrencyChange: (value: Currency) => void
  handleLanguageChange: (locale: Language, autoSet?: boolean) => void
  handleSetChanges: () => void
}

export const CurrencyAndLanguageContext =
  createContext<CurrencyAndLanguageType>({
    currentCurrency: Currency.Mxn,
    currentLocale: Language.Es,
    selectedCurrency: Currency.Mxn,
    selectedLocale: Language.Es,
    handleCurrencyChange: () => {},
    handleLanguageChange: () => {},
    handleSetChanges: () => {}
  })

const SAVE_USER_PREFERENCES = gql`
  mutation SaveUserPreferences($userChanges: RequestPreferencesInput!) {
    saveUserPreferences(userChanges: $userChanges) {
      user {
        currency
        language
      }
    }
  }
`

const CurrencyAndLanguageProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { i18n } = useTranslation('nav')
  const router = useRouter()

  const [currentCurrency, setCurrentCurrency] = useState<Currency>(Currency.Mxn)
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(
    Currency.Mxn
  )
  const [currentLocale, setCurrentLocale] = useState<Language>(Language.Es)
  const [selectedLocale, setSelectedLocale] = useState<Language>(Language.Es)

  const { currentUser, updateCurrentUser } = useAuthProvider()
  const [saveUserPreferences] = useMutation<
    Pick<IMutation, 'saveUserPreferences'>,
    IMutationSaveUserPreferencesArgs
  >(SAVE_USER_PREFERENCES, {
    onCompleted: ({ saveUserPreferences }) => {
      updateCurrentUser(() => ({
        currentUser: {
          ...currentUser,
          ...saveUserPreferences.user
        }
      }))
    }
  })

  const handleCurrencyChange = useCallback(
    (currency: Currency, autoSet?: boolean) => {
      setSelectedCurrency(currency)
      if (autoSet) {
        setCookie(null, 'currency', currency, {
          maxAge: null,
          path: '/'
        })
        setCurrentCurrency(currency)
        router.reload()
        saveUserPreferences({ variables: { userChanges: { currency } } })
      }
    },
    [router.isReady]
  )

  const handleLanguageChange = useCallback(
    (locale: Language, autoSet?: boolean) => {
      setSelectedLocale(locale)
      if (autoSet) {
        const { pathname, asPath, query } = router
        setCookie(null, 'locale', locale, {
          maxAge: null,
          path: '/'
        })
        setCurrentLocale(locale)
        i18n.changeLanguage(locale)
        router.push({ pathname, query }, asPath, { locale: locale })
        saveUserPreferences({
          variables: { userChanges: { language: locale } }
        })
      }
    },
    [router.query, i18n]
  )

  const handleSetChanges = useCallback(() => {
    if (currentCurrency !== selectedCurrency) {
      setCookie(null, 'currency', selectedCurrency, {
        maxAge: null,
        path: '/'
      })
      setCurrentCurrency(selectedCurrency)
    }

    if (currentLocale !== selectedLocale) {
      setCookie(null, 'locale', selectedLocale, {
        maxAge: null,
        path: '/'
      })
      i18n.changeLanguage(selectedLocale)
      setCurrentLocale(selectedLocale)
    }

    if (
      currentLocale !== selectedLocale ||
      currentCurrency !== selectedCurrency
    ) {
      const { pathname, asPath, query } = router
      const locale =
        currentLocale !== selectedLocale ? selectedLocale : undefined
      router.push({ pathname, query }, asPath, { locale })

      if (currentUser) {
        saveUserPreferences({
          variables: {
            userChanges: {
              currency: selectedCurrency,
              language: selectedLocale
            }
          }
        })
      }
    }
  }, [
    currentCurrency,
    currentLocale,
    selectedCurrency,
    selectedLocale,
    i18n,
    router
  ])

  useEffect(() => {
    const cookies = parseCookies()
    const localeCookie = cookies.locale
    const locale = Object.values(Language).find((l) => l === localeCookie)
    const currencyCookie = cookies.currency
    const currency = Object.values(Currency).find((c) => c === currencyCookie)

    if (locale && locale !== currentLocale) {
      const { pathname, asPath, query } = router
      i18n.changeLanguage(locale)
      router.push({ pathname, query }, asPath, { locale })
      setSelectedLocale(locale)
      setCurrentLocale(locale)
    }

    if (currency && currency !== currentCurrency) {
      setSelectedCurrency(currency)
      setCurrentCurrency(currency)
    }
  }, [])

  const debouncedUser = useDebounce(currentUser, 1000)
  useEffect(() => {
    if (!debouncedUser) return
    const { currency, language: locale } = debouncedUser

    if (locale && locale !== currentLocale) {
      const { pathname, asPath, query } = router
      i18n.changeLanguage(locale)
      router.push({ pathname, query }, asPath, { locale })
      setSelectedLocale(locale)
      setCurrentLocale(locale)
    }

    if (currency && currency !== currentCurrency) {
      setSelectedCurrency(currency)
      setCurrentCurrency(currency)
    }
  }, [debouncedUser])

  const value: CurrencyAndLanguageType = useMemo(
    () => ({
      currentCurrency,
      currentLocale,
      selectedCurrency,
      selectedLocale,
      handleCurrencyChange,
      handleLanguageChange,
      handleSetChanges
    }),
    [
      currentCurrency,
      currentLocale,
      selectedCurrency,
      selectedLocale,
      handleCurrencyChange,
      handleLanguageChange,
      handleSetChanges
    ]
  )

  return (
    <CurrencyAndLanguageContext.Provider value={value}>
      {children}
    </CurrencyAndLanguageContext.Provider>
  )
}

export default React.memo(CurrencyAndLanguageProvider)

export const useCurrencyAndLanguageProvider = (): CurrencyAndLanguageType =>
  useContext(CurrencyAndLanguageContext)
